import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Tabs, { TabList, Tab, TabPanel } from './../elements/Tabs';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class FeaturesTabs extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tabs section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tabs-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Nuestros clientes',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Tabs active="tab-a">
              <TabList>
                <Tab tabId="tab-a">
                  <div className="mb-12">
                    <Image
                      src={'https://craco.com.ar/static/media/logo.12c6049f.png'}
                      alt="Tab icon 01"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                  </div>
                </Tab>
                <Tab tabId="tab-a">
                  <div className="mb-12">
                    <Image
                      src={'https://adminavellanedalenceria.com/admin/logo/logo.png'}
                      alt="Tab icon 02"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                  </div>
                </Tab>
                <Tab tabId="tab-a">
                  <div className="mb-12">
                    <Image
                      src={'https://lenceriamaguen.ar/static/media/logo.331c0e1d.png'}
                      alt="Tab icon 03"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                  </div>
                </Tab>
                <Tab tabId="tab-a">
                  <div className="mb-12">
                    <Image
                      src={'https://iadatrade.com/static/media/logo.b3c42ba2.png'}
                      alt="Tab icon 04"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                  </div>
                </Tab>
                <Tab tabId="tab-a">
                  <div className=" mb-12">
                    <Image
                      src={'https://sandyproietti.com/static/media/logo.642e087c.png'}
                      alt="Tab icon 04"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                  </div>
                </Tab>
                
                <Tab tabId="tab-a">
                  <div className=" mb-12">
                    <Image
                      src={'https://mamilaindumentaria.com/static/media/logo.4db9c9d6.png'}
                      alt="Tab icon 04"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                  </div>
                </Tab>
                <Tab tabId="tab-a">
                  <div className="mb-12">
                    <Image
                      src={'https://bene-emeth.org/img/logo70.png'}
                      alt="Tab icon 04"
                      width={100}
                      height={100} />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    
                  </div>
                </Tab>
              </TabList>
          {   /* <TabPanel id="tab-a">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/features-tabs-image.png')}
                  alt="Features tabs image 01"
                  width={896}
                  height={504} />
              </TabPanel>
              <TabPanel id="tab-b">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/features-tabs-image.png')}
                  alt="Features tabs image 02"
                  width={896}
                  height={504} />
              </TabPanel>
              <TabPanel id="tab-c">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/features-tabs-image.png')}
                  alt="Features tabs image 03"
                  width={896}
                  height={504} />
              </TabPanel>
              <TabPanel id="tab-d">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/features-tabs-image.png')}
                  alt="Features tabs image 04"
                  width={896}
                  height={504} />
              </TabPanel>*/}
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTabs.propTypes = propTypes;
FeaturesTabs.defaultProps = defaultProps;

export default FeaturesTabs;